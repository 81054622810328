<template>
  <div class="pack-container">
    <div class="pack-content">
      <div class="content-wrapper">
        <div class="image-section">
          <img src="../assets/e7abierto.jpg" alt="Solarium" class="main-image" />
        </div>

        <!-- Detalle de la sesión -->
        <div class="details-section">
          <h1>Solárium + Colágeno</h1>

          <p class="promotion-text">
            🎄 Celebra esta Navidad con una piel radiante 🎁
            <br>
            Durante diciembre, llévate el pack de 10 sesiones y recibe 2 sesiones adicionales totalmente gratis. ¡Es nuestro regalo para ti!
          </p>

          <p class="session-description">
            Disfruta de una experiencia de bronceado profesional y segura en
            nuestra cabina de solarium. Cada sesión está diseñada para
            proporcionar un bronceado uniforme y saludable.
          </p>

          <!-- Selección de cantidad de sesiones usando botones -->
          <div class="session-buttons">
            <div class="button-group">
              <button v-for="pack in packs" :key="pack.id" :class="[
                'session-button',
                { active: selectedPack?.id === pack.id },
              ]" @click="selectPack(pack)">
                {{ pack.nombre }}
                <span v-if="pack.descripcion === '10'" class="promo-tag">+2 Sesiones Gratis 🎁</span>
              </button>
            </div>
          </div>

          <!-- Precio dinámico -->
          <div class="price-section" v-if="selectedPack">
            <div v-if="selectedPack.descripcion === '10'" class="price-promo">
              <span class="price-now">{{ formatPrice(selectedPack.precio) }}</span>
              <span class="promo-highlight"><br>¡Incluye 2 sesiones gratis!</span>
            </div>
            <div v-else>
              <span class="price-now">{{ formatPrice(selectedPack.precio) }}</span>
            </div>
          </div>


          <!-- Botón Comprar -->
          <button class="buy-button" @click="buyNow" :disabled="!selectedPack || !selectedPack.precio">
            Comprar Ahora
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="banner">
    <p>
      ¿Ya tienes tus sesiones?
      <a href="/agendar-sesion" class="banner-link">¡Agenda acá!</a>
    </p>
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import apiClient from "../api";

export default {
  setup() {
    const packs = ref([]); // Packs obtenidos de la API
    const selectedPack = ref(null); // Paquete seleccionado inicializado como null
    const priceRange = ref(""); // Rango de precios

    const store = useStore();
    const router = useRouter();

    // Método para comprar ahora y redirigir a la página de checkout
    const buyNow = () => {
      if (!selectedPack.value || !selectedPack.value.id) {
        alert("Por favor selecciona un pack de sesiones");
        return;
      }

      // Añadir la sesión seleccionada al carrito
      store.commit("agregarAlCarrito", {
        id: selectedPack.value.id,
        nombre: selectedPack.value.nombre,
        precio: selectedPack.value.precio,
        cantidad: 1,
        imagen: selectedPack.value.imagen,
      });

      // Redirigir al carrito o checkout
      router.push("/checkout");
    };

    // Seleccionar un pack
    const selectPack = (pack) => {
      selectedPack.value = pack;
    };

    // Formatear el precio
    const formatPrice = (value) => {
      return new Intl.NumberFormat("es-CL", {
        style: "currency",
        currency: "CLP",
      }).format(value);
    };

    // Cargar packs de la API al montar el componente
    onMounted(async () => {
      try {
        const response = await apiClient.get("/productos/sesiones");
        packs.value = response.data;

        // Ordenar los packs por el nombre o el ID
        const order = ["5", "10", "15", "1"];

        packs.value.sort((a, b) => {
          return order.indexOf(a.descripcion) - order.indexOf(b.descripcion);
        });

        // Inicializar selectedPack con el primer pack si está disponible
        if (packs.value.length > 0) {
          selectedPack.value = packs.value[0];
        }

        // Calcular el rango de precios
        const precios = packs.value.map((pack) => pack.precio);
        const minPrice = Math.min(...precios);
        const maxPrice = Math.max(...precios);
        priceRange.value = `${formatPrice(minPrice)} - ${formatPrice(
          maxPrice
        )}`;
      } catch (error) {
        console.error("Error al obtener los packs de sesiones:", error);
      }
    });

    return {
      packs,
      selectedPack,
      priceRange,
      selectPack,
      buyNow,
      formatPrice,
    };
  },
};
</script>

<style scoped>
* {
  box-sizing: border-box;
}

.pack-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 1em;
}

.content-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  max-width: 1200px;
  padding: 1em;
  background-color: #fff;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
}

.image-section {
  flex: 1;
  text-align: center;
}

.main-image {
  max-width: 100%;
  height: auto;
  border-radius: 1em;
}

.details-section {
  flex: 1;
  margin-left: 2em;
  text-align: left;
}

h1 {
  font-size: 2.5rem;
  color: #333;
  margin-bottom: 10px;
}

.session-subtitle {
  font-size: 1.2rem;
  color: #777;
}

.session-price-range {
  font-size: 1.8rem;
  font-weight: bold;
  margin: 10px 0;
}

.promotion-text {
  font-size: 1.1rem;
  color: red;
  margin-bottom: 15px;
}

.session-description {
  margin: 20px 0;
  font-size: 1.2rem;
  color: #555;
  text-align: left;
}

/* Estilos para los botones de sesiones */
.session-buttons {
  margin: 20px 0;
  text-align: left;
}

.button-group {
  display: flex;
  gap: 10px;
}

.session-button {
  padding: 10px 20px;
  border: 2px solid #7f5a35;
  background-color: white;
  color: #7f5a35;
  cursor: pointer;
  font-size: 1rem;
  border-radius: 5px;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.session-button:hover {
  background-color: #e3c727;
  color: white;
}

.session-button.active {
  background-color: #7f5a35;
  color: white;
}

.session-button.active:hover {
  background-color: #e3c727;
}

.price-section {
  margin: 20px 0;
  text-align: left;
}

.buy-button {
  background-color: #7f5a35;
  color: #fff;
  border: 1px solid #000;
  padding: 1em;
  width: 100%;
  cursor: pointer;
  margin-top: auto;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.buy-button:hover {
  background-color: #e3c727;
  color: #fff;
  border: 1px solid #7f5a35;
}

.banner {
  width: 100%;
  background-color: #ffdb58;
  color: #333;
  padding: 10px 0;
  text-align: center;
  font-size: 1.2rem;
  top: 0;
  z-index: 1000;
}

.banner p {
  margin: 0;
}

.banner-link {
  color: #7f5a35;
  font-weight: bold;
  text-decoration: none;
}

.banner-link:hover {
  text-decoration: underline;
}

.price-before {
  text-decoration: line-through;
  color: #888;
  font-size: 1.2rem;
}

.price-now {
  font-weight: bold;
  color: #000;
  font-size: 1.8rem;
}

.promo-tag {
  background-color: #e3c727;
  color: #fff;
  font-size: 0.8rem;
  padding: 3px 6px;
  margin-left: 5px;
  border-radius: 5px;
  font-weight: bold;
}

.price-promo {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.promo-highlight {
  background-color: #ffdb58;
  color: #7f5a35;
  font-size: 1rem;
  font-weight: bold;
  padding: 5px 10px;
  margin-top: 10px;
  border-radius: 5px;
  text-align: center;
}

@media (max-width: 768px) {
  .pack-content {
    width: 100%;
    padding: 1em;
  }

  .content-wrapper {
    flex-direction: column;
  }

  .details-section {
    margin-left: 0;
    margin-top: 1em;
    text-align: center;
  }

  .button-group {
    flex-direction: column;
    width: 100%;
  }

  .session-button {
    width: 100%;
    padding: 10px;
  }

  .buy-button {
    width: 100%;
  }
}
</style>
